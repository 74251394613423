/*Custom Fonts*/
@font-face{
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

.App {
  text-align: center;
}

/* Default CSS */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body{
  font-weight: 400;
  overflow-x:hidden;
  position:relative;
  background-color: #121212 !important;
  color:#fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding:0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

/* Navbar Css */
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

.navbar-brand img{
  height:85px;
  width:auto;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 16px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
  margin-right:-70px;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 16px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

button.navbar-toggler.collapsed{
  height:10px;
  width:50px;
  background-color:#772d83;
  border:2px solid white;
}
button.navbar-toggler {
  height: 10px;
  width: 50px;
  background-color: #772d83;
  border: 2px solid white;
}




/* Banner Css */
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/universe.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}


.bttn{
      background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
      border: 1px solid rgba(255, 255, 255, 0.5);
      display: inline-block;
      padding:15px;
      margin-bottom: 25px;
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner h2{
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
}
     .txt-rotate {
       background: linear-gradient(90.21deg, #cc4696 -5.91%, #5535e0 111.58%);
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
       height:60px;
}

.banner p {
  color: #fcedfd;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  background-color: rgb(6 4 8 / 40%);
  border-radius: 15px;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  animation: updown 3s linear infinite;
  border-radius: 50%;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}
/* Skills */
.skill{
  padding: 1 1 50px 0;
  position:relative;
  background-color:#000;
}

.skill-bx{
  background: #151515;
  border-radius: 40px;
  text-align:center;
  padding: 50px 50px;
  margin-top: -70px;
}

.skillz{
    background: #151515;
      border-radius: 64px;
      text-align: center;
      padding: 60px 50px;
      margin-top: -60px;
}

.skill h2{
  font-size:45px;
  font-weight:700;
}

.skill p{
  color: #888;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height:1.5em;
  margin:14px 0.75 0;
}

.skill-slider{
  width:80%;
  margin: 0 auto;
  position:relative;
}
.skill-slider img{
  width:30%;
  margin: 0 auto 15px auto;
}

.item{
  margin-top:15px;
}

.background-image-left{
  top:28%;
  bottom:0;
  position:absolute;
  width:40%;
  z-index:-4;
}

/* Projects */

.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}

.display{
  position: sticky;
  top: 0;
  left: 0;
  padding-top:100px;
  z-index: 1;
  background-color: black;
}

.mb-4.col-md-6.col-sm-6 {
  width: 40%;
  margin: auto;
  position: relative;
  z-index: 0;
  margin-bottom: -1% !important;
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin:auto;
  margin-bottom: 25px !important;
  text-align: center;
  width: 80%;
}

.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  height:250px;
}

.proj-imgbx img{
  height: 300px;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position:absolute;
  text-align: center;
  top: 65%; 
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 90%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.8px;
}


.text-center.mt-3 button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 20px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.text-center.mt-3 button span {
  z-index: 1;
}

.text-center.mt-3 button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.text-center.mt-3 button:hover {
  color: #000000;;
}

.text-center.mt-3 button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  outline: none;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

.contact {
  background : url("./assets/img/contact.png");
  padding: 60px 200px ;
}

.contact img {
  width: 92%;
    }
    

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}

/*Education */

.eduContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  padding: 0px 0px 60px 0px;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1350px;
  padding: 40px 0px 0px 0px;
  gap: 12px;
}

.title {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
}

.desc {
  color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
}

.timeline-section {
  width: 100%;
  max-width: 1000px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.cards {
  width: 650px;
  border-radius: 10px;
  border: solid 1px rgb(153, 79, 153) ;
  box-shadow: rgb(180 54 219 / 15%) 0px 4px 24px;
  padding: 12px 16px;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 12px;
  transition: all 0.3s ease-in-out;
}

.cards:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.cards:hover .document {
  display: flex;
}

.cards:hover .spann {
  overflow: visible;
  -webkit-line-clamp: unset;
}

.cards-border {
  border: 0.1px solid #854CE6;
}

.top {
  width: 100%;
  display: flex;
  gap: 12px;
}

.image {
  height: 50px;
  background-color: #000;
  border-radius: 10px;
  margin-top: 4px;
  width:auto;
}

.image:hover {
  cursor: pointer;
  opacity: 0.8;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}

.degree {
  font-size: 14px;
  font-weight: 500;
  color: #c9c9c9;;
}

.date {
  font-size: 12px;
  font-weight: 400;
  color: #999;
}

.grade {
  font-size: 14px;
  font-weight: 500;
  color: #c9c9c9;;
}

.description {
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 10px;
}

.spann {
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.certification-card {
  width: 650px;
  border-radius: 10px;
  border: solid 1px rgb(153, 79, 153);
  box-shadow: rgb(180 54 219 / 15%) 0px 4px 24px;
  padding: 12px 16px;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 12px;
  transition: all 0.3s ease-in-out;
}

.certification-card:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.certification-card .body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.certification-card .name {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}

.certification-card .affiliation {
  font-size: 20px;
  font-weight: 500;
  color: #c9c9c9;
}

.certification-card .date {
  font-size: 16px;
  font-weight: 400;
  color: #999;
}

 .certification-card .link a {
    color: rgb(153, 79, 153);
    font-size: 16px;
    font-weight: 500;
  }


  

/* Footer Css */
.footer {
  padding: 50px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}


#col{
  margin-top: 11px;
}


.footer img {
  width: 26%;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
}

.footer .container{
  display:flex;
}

@media only screen and (max-width: 990px) {
  .container {
    margin-left: 0;
  }

  .me-auto.navbar-nav {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-content: space-around;
    align-items: baseline;
    margin: auto;
  }

  span.navbar-text {
    visibility: hidden;
  }

  nav.scrolled.navbar.navbar-expand-lg.navbar-light {
    height: 7%;
  }
}

@media (min-width: 768px) {
  .d-block.d-md-none {
    display: none;
  }
}


@media only screen and (max-width: 768px) {
  .wrapper{
    padding:40px 0 ;
  }
  
  .contact {
    padding: 30px;
  }
  
  button.navbar-toggler {
    margin-right: 0px;
  }
  
  nav.scrolled.navbar.navbar-expand-lg.navbar-light {
    height: 9%;
   }

  .mb-4.col-md-6.col-sm-6 {
    width: 85%;
  }

    .certification-card {
      padding: 10px;
      gap: 8px;
      width: 300px;
    }
  
  
    .certification-card .name {
      font-size: 14px;
    }
  
    .certification-card .affiliation {
      font-size: 12px;
    }
  
    .certification-card .date {
      font-size: 10px;
    }
  
    .certification-card .link {
      font-size: 12px;
    }

        .eduContainer {
          padding: 0;
        }
    
        .title {
          margin-top: 12px;
          font-size: 32px;
        }
    
        .desc {
          font-size: 16px;
        }
    
        .cards {
          padding: 10px;
          gap: 8px;
          width: 300px;
        }
    
        .image {
          height: 40px;
        }
    
        .name {
          font-size: 14px;
        }
    
        .degree {
          font-size: 12px;
        }
    
        .date {
          font-size: 10px;
        }
    
        .grade {
          font-size: 12px;
        }
    
        .description {
          font-size: 12px;
        }
}

@media only screen and (max-width: 600px) {
  button.navbar-toggler {
    margin-right: 100px;
  }
    .banner img {
      animation: updown 3s linear infinite;
      border-radius: 50%;
      width: 60%;
      margin: 30px;
    }
}

@media only screen and (max-width: 430px) {
  .container {
    margin-left: 30px;
  }

    h2 {
      font-size: 36px !important;
    }
  
    p {
      font-size: 16px !important;
    }

  .banner h1 {
    font-size: 55px;
  }

  .banner p {
    width: 100%
  }

  .banner h2 {
    font-size: 34px;
  }

  .me-auto.navbar-nav {
    display: flex;
    flex-direction: column;
    margin-left: 175px;
  }

  nav.scrolled.navbar.navbar-expand-lg.navbar-light {
    height: 10%;
  }

  .social-icon a {
    width: 30px;
    height: 30px;
  }
}


@media only screen and (max-width: 390px) {
  .me-auto.navbar-nav {
    margin-left: 130px;
  }

  nav.scrolled.navbar.navbar-expand-lg.navbar-light {
    height: 10%;
  }
}

@media only screen and (max-width: 375px) {
h2{
  font-size: 30px !important;
}
p{
  font-size: 14px !important;
}

  nav.scrolled.navbar.navbar-expand-lg.navbar-light {
    height: 13%;
  }

}